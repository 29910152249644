import React from "react"

import Nav from "./nav"
import Footer from "./footer"
import "../styles/main.scss"


const Layout = (props) => {

  return (
    <div className="site__bmw">
      <Nav site="BMW" />
        <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
