import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/sale-banner.jpg`;
const indybanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/feb-promo.jpg`;

const SpecialOffersYouTube = () => {
  const getFirstCard = () => {
    const today = new Date();
    const febPromo =
      today >= new Date(2024,1,1) &&
      today < new Date(2024,3,1);
    if (febPromo) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={indybanner}
            imageAlt="WAVING THE CHECKERED FLAG"
            header="WAVING THE CHECKERED FLAG"
            subheader="GET 20% OFF AN INDY EXPERIENCE"
            text="It’s our final lap at Indy, and we’ve got a special offer. Book your class by March 31, and you’ll receive 20% off a Full-day M Intensive Experience."
            btnText="Call 888-345-4269 to book now"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              Use promo code <span className="bold">24YTIndy20</span>. Must purchase class by 3/31/24. Valid for Indianapolis Experiences only.
            </p>
          </FlexCard>
          <br />
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersYouTube;
