import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class GiftCardBackground extends React.Component {
  state = {
    width: ''
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    })
    window.addEventListener('resize', this.handleWindowSizeChange);
   

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  scrollToRef = () => {
    window.scrollTo({
      top: 1000, 
      left: 0, 
      behavior: 'smooth' 
    });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 767;

    return (
      <section className="hero__header">
        {isMobile ? (
          <LazyLoadImage effect="blur" src={this.props.imageMobile} alt={this.props.imageAlt} className="img-mobile" />
        ) : (
          <LazyLoadImage effect="blur" src={this.props.imageDesktop} alt={this.props.imageAlt} className="img-desktop" />
        )}
      </section>
    );
  }
}

export default GiftCardBackground;